import React, { useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import Search from "./search";
import { StaticQuery, graphql } from "gatsby";
import { FaTwitter, FaFacebook, FaSearch } from "react-icons/fa";

// Styles
import "../../styles/app.css";

/**
 * Main layout component
 *
 * The Layout component wraps around each page and template.
 * It also provides the header, footer as well as the main
 * styles, and meta data for each page.
 *
 */
const DefaultLayout = ({ data, children, bodyClass }) => {
    const url = typeof window !== "undefined" ? window.location.pathname : "";
    const [isSearchActive, setIsSearchActive] = useState(false);
    const toggleSearch = () => {
        setIsSearchActive(!isSearchActive);
    };
    const site = data.allGhostSettings.edges[0].node;
    console.log(site);
    const twitterUrl = site.twitter
        ? `https://twitter.com/${site.twitter.replace(/^@/, "")}`
        : null;
    const facebookUrl = site.facebook
        ? `https://www.facebook.com/${site.facebook.replace(/^\//, "")}`
        : null;

    return (
        <>
            <Helmet>
                <html lang={site.lang} />
                <head dangerouslySetInnerHTML={site.codeinjection_foot}>
                    {site.codeinjection_head}
                </head>
                <style type="text/css">{`${site.codeinjection_styles}`}</style>

                <body className={bodyClass} />
            </Helmet>

            <div className="global-wrap">
                <div className="global-content">
                    {/* The main header section on top of the screen */}
                    <header className="header-section">
                        <div className="header-wrap">
                            <div className="header-logo">
                                {site.logo ? (
                                    <>
                                        <a href="/" className="is-image">
                                            <img
                                                src={site.logo}
                                                alt={site.title}
                                            />
                                        </a>
                                    </>
                                ) : (
                                    <>
                                        <a href="/" className="is-title">
                                            {site.title}
                                        </a>
                                        s
                                    </>
                                )}
                            </div>
                            <div className="header-nav">
                                <input
                                    id="toggle"
                                    className="header-checkbox"
                                    type="checkbox"
                                />
                                <label
                                    className="header-toggle"
                                    htmlFor="toggle"
                                >
                                    <span>
                                        <span className="bar"></span>
                                        <span className="bar"></span>
                                        <span className="bar"></span>
                                    </span>
                                </label>
                                <nav>
                                    <ul>
                                        {site.navigation.map((item, index) => (
                                            <li key={index}>
                                                <a
                                                    href={item.url}
                                                    className={`${
                                                        url === item.url &&
                                                        "is-active"
                                                    }`}
                                                >
                                                    {item.label}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                    <ul>
                                        <a href="/products">
                                            <li>Products</li>
                                        </a>

                                        <li
                                            id="search-open"
                                            className="header-search"
                                            onClick={toggleSearch}
                                        >
                                            <span>Search</span>
                                            <FaSearch />
                                            {isSearchActive && (
                                                <Search
                                                    posts={
                                                        data.allGhostPost.edges
                                                    }
                                                />
                                            )}
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </header>

                    <main className="global-main">
                        {/* All the main content gets inserted here, index.js, post.js */}
                        {children}
                    </main>
                    <footer className="footer-section global-footer">
                        <div className="footer-wrap">
                            <div className="footer-data">
                                <div className="footer-logo">
                                    {site.logo ? (
                                        <a href="/" className="is-image">
                                            <img
                                                src={site.logo}
                                                alt={site.title}
                                            />
                                        </a>
                                    ) : (
                                        <a href="/" className="is-title">
                                            {site.title}
                                        </a>
                                    )}
                                </div>
                                {site.description && (
                                    <p className="footer-description">
                                        {site.description}
                                    </p>
                                )}

                                <div className="footer-icons">
                                    {site.facebook && (
                                        <a
                                            href={facebookUrl}
                                            aria-label="link Facebook"
                                        >
                                            <FaFacebook />
                                        </a>
                                    )}

                                    {site.twitter && (
                                        <a
                                            href={twitterUrl}
                                            aria-label="link Twitter"
                                        >
                                            <FaTwitter />
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="footer-copyright">
                            {site.title} &copy; 2021. All Right Reserved
                        </div>
                    </footer>
                </div>
            </div>
        </>
    );
};

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
        allGhostPost: PropTypes.array,
    }).isRequired,
};

const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                allGhostPost {
                    edges {
                        node {
                            ...GhostPostFields
                        }
                    }
                }
                file(relativePath: { eq: "ghost-icon.png" }) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
);

export default DefaultLayoutSettingsQuery;
