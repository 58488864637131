import React, { useState } from "react";
import Fuse from "fuse.js";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Search = ({ posts }) => {
    const postData = posts.map(post => post.node);
    console.log("search", postData);
    const options = {
        includeScore: true,
        keys: ["slug"],
    };

    const fuse = new Fuse(postData, options);
    const [result, setResult] = useState([]);

    const onSearchChange = (e) => {
        const keyword = e.target.value;
        console.log(fuse.search(keyword));
        setResult(fuse.search(keyword));
    };

    return (
        <div id="search-section" className="search-section">
            <span id="search-close" className="search-close">
                X
            </span>
            <div id="search-content" className="search-content">
                <form className="search-form">
                    <input
                        autoFocus
                        autoComplete="off"
                        id="search-input"
                        type="text"
                        placeholder="Type your keywords"
                        onChange={onSearchChange}
                    />
                    <div className="search-meta">
                        {result.length} Results for your search
                    </div>
                </form>
                <div id="search-results" className="search-results">
                    {result.map(({ item }) => (
                        <>
                            {" "}
                            <small>
                                Published - {item.published_at_pretty}
                            </small>
                            <Link key={item.slug} to={`/${item.slug}`}>
                                {item.title}
                            </Link>
                        </>
                    ))}
                </div>
            </div>
            <div id="search-overlay" className="search-overlay"></div>
        </div>
    );
};

Search.propTypes = {
    posts: PropTypes.array,
};

export default Search;
